import {css} from '@emotion/react';
import {Button, Divider, notification, Select} from 'antd';
import React, {useContext, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {EXPORT_ACTION} from '../../../../../api/dataQuery/microDataQuery';
import IconFont from '../../../../../components/IconFont';
import {DICTTYPES} from '../../../../../constants/base';
import {downloadBackEndFilename} from '../../../../../helpers/request/download';
import useSafeState from '../../../../../hooks/useSafeState';
import useSerialFilter from '../../../../../hooks/useSerialFilter';
import useSysSettingData from '../../../../../hooks/useSysSettingData';
import {numberWithCommas} from '../../helpers';
import useRequestFilters from '../../hooks/useRequestFilters';

const ExportBtn = ({exportHistoryModalControl = (v) => v}) => {
  const {wideOverview} = useContext(MicroDataQueryContext);

  const [isExporting, setIsExporting] = useSafeState(false);
  const [exportBtnOpen, setExportBtnOpen] = useSafeState(false);

  const [searchParams] = useSearchParams();

  const {objectData: misDetailData = {}} = useSysSettingData(
    DICTTYPES.MIS_SETTING
  );

  const [getRequestFilters] = useRequestFilters();

  const wideId = useMemo(() => {
    return searchParams.get('wideId');
  }, [searchParams]);

  const originFilterConditions = useSerialFilter(`dataQueryTable${wideId}`);

  const formatExportList = useMemo(() => {
    const {csvMaxExport, excelMaxExport} = misDetailData;

    const _csvMaxExport = numberWithCommas(csvMaxExport ?? 0);
    const _excelMaxExport = numberWithCommas(excelMaxExport ?? 0);

    const formatList = [
      // {
      //   title: 'CSV(显示字段)',
      //   colFlag: 1,
      //   exportFormat: 1,
      //   list: [
      //     {label: '当前页面', value: '当前页面', dataType: 1},
      //     {
      //       label: `当前筛选(最多${_csvMaxExport}条)`,
      //       value: `当前筛选(最多${_csvMaxExport}条)`,
      //       dataType: 2,
      //       display: originFilterConditions.length > 0,
      //     },
      //     {
      //       label: `所有数据(最多${_csvMaxExport}条)`,
      //       value: `所有数据(最多${_csvMaxExport}条)`,
      //       dataType: 3,
      //     },
      //   ],
      // },
      {
        title: 'Excel(所有字段)',
        colFlag: 2,
        exportFormat: 2,
        list: [
          {
            label: `当前筛选(最多${_excelMaxExport}条)`,
            value: `当前筛选(最多${_excelMaxExport}条)`,
            dataType: 2,
            display: originFilterConditions.length > 0,
          },
          {
            label: `所有数据(最多${_excelMaxExport}条)`,
            value: `所有数据(最多${_excelMaxExport}条)`,
            dataType: 3,
          },
          {label: '当前页面', value: '当前页面', dataType: 1},
        ],
      },
      {
        title: 'CSV(所有字段)',
        colFlag: 2,
        exportFormat: 1,
        list: [
          {
            label: `当前筛选(最多${_csvMaxExport}条)`,
            value: `当前筛选(最多${_csvMaxExport}条)`,
            dataType: 2,
            display: originFilterConditions.length > 0,
          },
          {
            label: `所有数据(最多${_csvMaxExport}条)`,
            value: `所有数据(最多${_csvMaxExport}条)`,
            dataType: 3,
          },
          {label: '当前页面', value: '当前页面', dataType: 1},
        ],
      },
      // {
      //   title: 'Excel(显示字段)',
      //   colFlag: 1,
      //   exportFormat: 2,
      //   list: [
      //     {label: '当前页面', value: '当前页面', dataType: 1},
      //     {
      //       label: `当前筛选(最多${_excelMaxExport}条)`,
      //       value: `当前筛选(最多${_excelMaxExport}条)`,
      //       dataType: 2,
      //       display: originFilterConditions.length > 0,
      //     },
      //     {
      //       label: `所有数据(最多${_excelMaxExport}条)`,
      //       value: `所有数据(最多${_excelMaxExport}条)`,
      //       dataType: 3,
      //     },
      //   ],
      // },
    ];
    return formatList;
  }, [misDetailData, originFilterConditions]);

  const onExportHandle = (colFlag, dataType, exportFormat) => {
    const {
      queryFilterCals = [],
      staticFilters = [],
      sectionFilters = [],
      filterCondition,
    } = getRequestFilters();

    setIsExporting(true);

    try {
      downloadBackEndFilename(
        EXPORT_ACTION,
        {
          colFlag,
          dataType,
          exportFormat,
          pageDomain: {
            isAsc: '',
            orderBy: '',
            orderByColumn: '',
            pageNum: searchParams.get('pageNum') ?? 1,
            pageSize: searchParams.get('pageSize') ?? 20,
          },
          pathId: +searchParams.get('wideId'),
          queryFilterCals,
          staticFilters,
          sectionFilters,
        },
        null,
        {
          headers: {
            'filter-condition': JSON.stringify(filterCondition),
          },
        }
      );
      // const res = await exportMicroQuery(
      //   {
      //     'filter-condition': JSON.stringify(filterCondition),
      //   },
      //   {
      //     colFlag,
      //     dataType,
      //     exportFormat,
      //     pageDomain: {
      //       isAsc: '',
      //       orderBy: '',
      //       orderByColumn: '',
      //       pageNum: searchParams.get('pageNum') ?? 1,
      //       pageSize: searchParams.get('pageSize') ?? 20,
      //     },
      //     pathId: +searchParams.get('wideId'),
      //     queryFilterCals,
      //     staticFilters,
      //     sectionFilters,
      //   }
      // );
      // if (res?.code === 200) {
      //   notification.success({
      //     description: `${wideOverview.name}导出任务提交(id:${res?.data})成功`,
      //   });
      // } else {
      //   notification.error({
      //     description: `${wideOverview.name}导出任务提交失败`,
      //   });
      // }
    } catch (error) {
      notification.error({
        description: `${wideOverview.name}导出失败`,
      });
      throw new Error('export task commit failed');
    } finally {
      setIsExporting(false);
      setExportBtnOpen(false);
    }
  };

  return (
    <Select
      bordered={false}
      className="ant-select-suffix"
      css={css`
        .ant-select-arrow .anticon:not(.ant-select-suffix) {
          pointer-events: none;
        }
      `}
      disabled={!wideId || isExporting || !wideOverview?.managePerm}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => {
        return (
          <div
            css={css`
              padding: 2px 8px;
            `}
          >
            <Button
              size="small"
              style={{padding: 0}}
              type="link"
              onClick={() => {
                exportHistoryModalControl._toggle(true);
              }}
            >
              历史记录(数据只保留30天)
            </Button>
            {formatExportList.map((group, index) => {
              return (
                <div key={index}>
                  <Divider style={{margin: 0, padding: '0px 8px'}} />
                  <div
                    key={index}
                    style={{
                      padding: '5px 0px',
                      color: '#1A2230',
                      fontWeight: '500',
                    }}
                  >
                    {group.title}
                  </div>
                  {group.list.map((opt, _index) => {
                    if (opt.display === undefined) {
                      return (
                        <div
                          css={css`
                            &:hover {
                              background: rgba(203, 203, 203, 0.15);
                              cursor: pointer;
                            }
                          `}
                          key={_index}
                          style={{padding: '5px 0px'}}
                          onClick={() => {
                            onExportHandle(
                              group.colFlag,
                              opt.dataType,
                              group.exportFormat
                            );
                          }}
                        >
                          <p
                            css={css`
                              display: flex;
                              justify-content: space-between;
                              color: rgba(26, 34, 48, 0.8);
                            `}
                          >
                            <span>{opt.label}</span>
                          </p>
                        </div>
                      );
                    } else if (opt.display) {
                      return (
                        <div
                          css={css`
                            &:hover {
                              background: rgba(203, 203, 203, 0.15);
                              cursor: pointer;
                            }
                          `}
                          key={_index}
                          style={{padding: '5px 0px'}}
                          onClick={() => {
                            onExportHandle(
                              group.colFlag,
                              opt.dataType,
                              group.exportFormat
                            );
                          }}
                        >
                          <p
                            css={css`
                              display: flex;
                              justify-content: space-between;
                              color: rgba(26, 34, 48, 0.8);
                            `}
                          >
                            <span>{opt.label}</span>
                          </p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              );
            })}
          </div>
        );
      }}
      open={exportBtnOpen}
      size="small"
      suffixIcon={
        <IconFont
          style={{fontSize: 14, color: 'rgba(37, 49, 69, 0.8)'}}
          type="icon-arrow-down"
        />
      }
      value={isExporting ? '正在导出' : '导出'}
      onDropdownVisibleChange={(visible) => setExportBtnOpen(visible)}
    />
  );
};

export default ExportBtn;
