import {notification} from 'antd';

import {SQLSyntaxKeywords} from '../../../../constants/SQLSyntaxKey';
import {deepClone} from '../../../../helpers/utils';

export function addNodeHeight(treeData) {
  let _gapY = 1;
  function traverse(data) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (i > 0) {
        _gapY++;
      }
      item._gapY = _gapY;
      if (item.children.length) {
        traverse(item.children);
      }
    }
  }
  traverse(treeData);
}

export function copyMap(oldMap) {
  const _map = new Map();
  [...oldMap.entries()].forEach(([key, value]) => {
    _map.set(key, deepClone(value));
  });
  return _map;
}

export function validateWideTableColumns(columns = []) {
  for (let i = 0; i < columns.length; i++) {
    const {columnAlias, curShowName, isShow} = columns[i];
    const describe = isShow ? '显示名' : '隐藏字段';
    // 不可使用数据库语法作为字段名
    if (
      SQLSyntaxKeywords.some((key) =>
        new RegExp(`^${columnAlias}{1,${key.length}}$`, 'gi').test(key)
      )
    ) {
      notification.error({
        top: 60,
        key: 'columnAlias',
        description: `${describe}【${curShowName}】: 不可使用数据库语法作为字段名`,
      });
      return false;
    }
    // /^[A-Za-z][A-Za-z0-9_]*$/
    if (!/^[_a-zA-Z0-9]+$/gi.test(columnAlias)) {
      notification.error({
        top: 60,
        key: 'columnAlias',
        description: `${describe}【${curShowName}】: 仅支持英文大小写、数值、_`,
      });
      return false;
    }
    if (/^[0-9]+/gi.test(columnAlias)) {
      notification.error({
        top: 60,
        key: 'columnAlias',
        description: `${describe}【${curShowName}】: 不能为数字开头`,
      });
      return false;
    }
    if (/^_/gi.test(columnAlias)) {
      notification.error({
        top: 60,
        key: 'columnAlias',
        description: `${describe}【${curShowName}】: 不能为下划线开头`,
      });
      return false;
    }
  }
  return true;
}
